import React from "react";
import logo from '../images/tech4mation logo-02 1.svg';
// import x from '../images/twitter.svg';
import face from '../images/Facebook.svg';
import ig from '../images/Instagram.svg';
import lin from '../images/link.svg';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


const Footer = () =>{
    return(
        <section className="footer">
            <Helmet>
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
            </Helmet>

            <div className="top">
                <h1>
                    Contact us today and let our experts design the right solution for your needs.
                </h1>
                <Link class="calendly-inline-widget" to="https://calendly.com/salestech4mation" target="_blank" rel="noopener noreferrer">Book a Free Consultation</Link>
            </div>
            <footer>
                <div className="left">
                    <img src={logo} alt=""/>
                    <ul>
                        {/* <li><Link to="w" target="_blank"><img src={x} alt="" /></Link></li> */}
                        <li><Link to="https://www.facebook.com/Tech4mationOnline/" target="_blank"><img src={face} alt="" /></Link></li>
                        <li><Link to="https://instagram.com/tech4mationonline?igshid=MmVlMjlkMTBhMg==" target="_blank"><img src={ig} alt="" /></Link></li>
                        <li><Link to="https://www.linkedin.com/company/tech4mation/" target="_blank"><img src={lin} alt="" /></Link></li>
                    </ul>
                </div>
                <div className="right">
                    <ul>
                        <li>
                            <Link to="/about">
                                ABOUT US
                            </Link>
                        </li>
                        <li>
                            <Link to="/service">
                                OUR SERVICES
                            </Link>
                        </li>
                        <li>
                            <Link to="b">
                                OUR PROCESS
                            </Link>
                        </li>
                    </ul>

                    <p>© 2023 Tech4mation LTD. All Rights Reserved</p>
                </div>
            </footer>
        </section>
    )
}

export default Footer;