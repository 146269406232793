import React from "react";
import Header from "../comp/header";
import Hero from "../comp/hero";
import Who from "./about/who";
import Test from "./home/testi";
import Footer from "../comp/footer";
import MisVis from "./about/misVis";


const About = () =>{
    return(
        <section className="about">
            <Header/>
            <div className="holding">
                <Hero title={'Welcome to Tech4mation'}/>
                <Who/>
                <MisVis/>
                <Test/>
            </div>
            <Footer/>
        </section>            
    )            
}

export default About;