import React from "react";
import Header from "../comp/header";
import Hero from "../comp/hero";
import Footer from "../comp/footer";
import Test from "./home/testi";
import Feel from "./contact/feel";
import Touch from "./contact/touch";


const Contact = () =>{
    return(
        <section className="contact">
            <Header/>
            <div className="holding">
                <Hero title={"Contact Us"}/>
                <Feel/>
                <Touch/>
                <Test/>
                <Footer/>
            </div>
        </section>
    )
}

export default Contact;