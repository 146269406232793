import React from "react";
import loc from '../../images/loc.svg';
import mail from '../../images/mail.svg';
import call from '../../images/call.svg';


const Feel = () =>{
    return(
        <section className="feel">
            <div className="top">
                <h1>Feel free to contact us</h1>
                <p>Our team would love to hear from you</p>
            </div>
            <div className="bot">
                <div className="hold">
                    <div className="img"></div>
                    <h4>Office Address</h4>
                    <p>Gbangbala Street, Ikate Elegushi Lekki, Lagos, Nigeria</p>
                </div>
                <div className="hold">
                    <div className="img img2"></div>
                    <h4>Email Address</h4>
                    <p>info@tech4mationltd.com</p>
                </div>
                <div className="hold">
                    <div className="img img3"></div>
                    <h4>Phone Number</h4>
                    <p>+234 809 042 6106</p>
                </div>
            </div>
        </section>
    )
}

export default Feel;