import React from "react";
import pic from '../../images/us.png';
import picMob from '../../images/anoutMob.png';
import { Link } from "react-router-dom";

const AboutUs = () =>{
    return(
        <section className="us">
            <div className="img">
                <img src={pic} alt="" className="des"/>
                <img src={picMob} alt="" className="mob" />
            </div>
            <div className="text">
                <h4>
                    ABOUT US
                </h4>
                <h2>
                    We Provide Solutions, Inspired by Innovation.
                </h2>
                <p>
                    Tech4mation LTD is an indigenous Information Technology Company based in Lagos State, with branches in Abuja and Anambra, Nigeria. Established in 2010, our areas of competence include Telecom infrastructure deployment and support, Web and mobile App development, Telecoms Consulting, I.T support Tech4mation Ltd currently partners with the largest telecom providers in Nigeria to provide the building blocks that enable service providers build cloud-based communications services such as voice, video, web conferencing Since 2010, our platform has enabled over 25 communication service providers across Nigeria and West Africa to offer and monetise multiple telecom services.
                </p>
                <Link to="/about">
                    Learn More
                </Link>
            </div>
        </section>
    )
}

export default AboutUs;