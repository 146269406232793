import React from "react";

const Touch = () =>{
    return(
        <section className="touch">
            <div className="form">
                <h1>Get in touch</h1>
                <p>We are here to assist you or answer any questions you may have</p>
                <form action="">
                    <div id="hold">
                        <div>
                            <label htmlFor="firstName">First name </label>
                            <input type="text" id="firstName" name="firstName" placeholder="First name" />
                        </div>
                        <div>
                            <label htmlFor="lastName">Last name </label>
                            <input type="text" id="lastName" name="lastName" placeholder="Last name"/>
                        </div>
                    </div>
                    <div className="hold">
                        <label htmlFor="email">Email </label>
                        <input type="email" id="email" name="email" placeholder="you@company.com"/>
                    </div>
                    <div className="hold">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" cols="30" rows="10" placeholder="Write your message…"></textarea>
                    </div>

                    <button type="submit">Submit</button>
                </form>
            </div>
            <div className="img"></div>
        </section>
    )
}

export default Touch;